
    import {Component, Vue, Inject, Prop, Watch} from 'vue-property-decorator';
    import Util from '@/lib/util'
    import AbpBase from '@/lib/abpbase'
    import PageRequest from '@/store/entities/page-request'
    import CreateUser from './create-user.vue'
    import EditUser from './edit-user.vue'
    import RegisterUserForContact from "@/store/entities/companycontext/registeruserforcontact";
    import ImpersonateInput from "@/store/entities/impersonateinput";
    import UserNotificationForm from "@/views/setting/user/user-notify.vue";
    import JobUserNotifyForm from "@/views/setting/user/job-user-notify.vue";

    class PageUserRequest extends PageRequest {
        keyword: string;
        isActive: boolean = null;//nullable
        from: Date;
        to: Date;
    }

    @Component({
        components: {CreateUser, EditUser, UserNotificationForm, JobUserNotifyForm}
    })
    export default class Users extends AbpBase {
        edit() {
            this.editModalShow = true;
        }

        //filters
        pagerequest: PageUserRequest = new PageUserRequest();
        creationTime: Date[] = [];

        createModalShow: boolean = false;
        editModalShow: boolean = false;
        notifyModalShow: boolean = false;
        jobNotifyModalShow: boolean = false;

        notify() {
            this.notifyModalShow = true;
        }

        get list() {
            return this.$store.state.user.list;
        };

        get loading() {
            return this.$store.state.user.loading;
        }

        create() {
            this.createModalShow = true;
        }

        isActiveChange(val: string) {
            if (val === 'Actived') {
                this.pagerequest.isActive = true;
            } else if (val === 'NoActive') {
                this.pagerequest.isActive = false;
            } else {
                this.pagerequest.isActive = null;
            }
        }

        pageChange(page: number) {
            this.$store.commit('user/setCurrentPage', page);
            this.getpage();
        }

        pagesizeChange(pagesize: number) {
            this.$store.commit('user/setPageSize', pagesize);
            this.getpage();
        }

        notifyClick() {
            this.jobNotifyModalShow = true;
        }

        async getpage() {

            this.pagerequest.maxResultCount = this.pageSize;
            this.pagerequest.skipCount = (this.currentPage - 1) * this.pageSize;
            //filters

            if (this.creationTime.length > 0) {
                this.pagerequest.from = this.creationTime[0];
            }
            if (this.creationTime.length > 1) {
                this.pagerequest.to = this.creationTime[1];
            }

            await this.$store.dispatch({
                type: 'user/getAll',
                data: this.pagerequest
            })
        }

        get pageSize() {
            return this.$store.state.user.pageSize;
        }

        get totalCount() {
            return this.$store.state.user.totalCount;
        }

        get currentPage() {
            return this.$store.state.user.currentPage;
        }

        columns = [{
            title: this.L('UserName'),
            key: 'userName'
        }, {
            title: this.L('Name'),
            key: 'name'
        }, {
            title: this.L('IsActive'),
            render: (h: any, params: any) => {
                return h('span', params.row.isActive ? this.L('Yes') : this.L('No'))
            }
        }, {
            title: this.L('CreationTime'),
            key: 'creationTime',
            render: (h: any, params: any) => {
                return h('span', new Date(params.row.creationTime).toLocaleDateString())
            }
        }, {
            title: this.L('Actions'),
            key: 'Actions',
            width: 335,
            render: (h: any, params: any) => {
                return h('div', [
                    h('Button', {
                        props: {
                            type: 'primary',
                            size: 'small'
                        },
                        style: {
                            marginRight: '5px',
                            visibility: params.row.hasAccount === "Yes" ? 'hidden' : 'visible'
                        },
                        on: {
                            click: async () => {
                                await this.$store.dispatch({
                                    type: 'app/impersonate',
                                    data: new ImpersonateInput(params.row.id, null)
                                });
                                await this.getpage();
                            }
                        }
                    }, this.L('Login as this user')),
                    h('Button', {
                        props: {
                            type: 'primary',
                            size: 'small'
                        },
                        style: {
                            marginRight: '5px'
                        },
                        on: {
                            click: () => {
                                this.$store.commit('user/edit', params.row);
                                this.edit();
                            }
                        }
                    }, this.L('Edit')),
                    h('Button', {
                        props: {
                            type: 'error',
                            size: 'small'
                        }, style: {
                            marginRight: '5px'
                        },
                        on: {
                            click: async () => {
                                this.$Modal.confirm({
                                    title: this.L('Tips'),
                                    content: this.L('DeleteUserConfirm'),
                                    okText: this.L('Yes'),
                                    cancelText: this.L('No'),
                                    onOk: async () => {
                                        await this.$store.dispatch({
                                            type: 'user/delete',
                                            data: params.row
                                        })
                                        await this.getpage();
                                    }
                                })
                            }
                        }
                    }, this.L('Delete')),
                    h('Button', {
                        props: {
                            type: 'primary',
                            size: 'small'
                        },
                        style: {
                            marginRight: '5px'
                        },
                        on: {
                            click: async () => {
                                await this.$store.commit('user/edit', params.row);
                                this.notify();
                            }
                        }
                    }, this.L('Notify'))
                ])
            }
        }]

        async created() {
            await this.getpage();
            await this.$store.dispatch({
                type: 'user/getRoles'
            })
        }
    }
